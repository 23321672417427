<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-navigation-drawer v-model="sidebar_drawer" :mini-variant.sync="sidebar_mini_menu" clipped fixed app
                         mini-variant-width="56">

        <template v-slot:prepend>
            <v-list-item dense class="user_slot">
                <v-list-item-icon :title="$store.state.user_details.name">
                    <v-icon color="success">account_circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$store.state.user_details.name}}</v-list-item-title>
            </v-list-item>
        </template>

        <v-divider class="mb-2"></v-divider>

        <v-list dense>

            <!--Dashboard-->
            <v-list-item to="/" :title="$t('general.dashboard')">
                <v-list-item-icon>
                    <v-icon>dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('general.dashboard')}}</v-list-item-title>
            </v-list-item>

            <!--Incidents-->
            <v-list-item v-if="canViewIncident" @click="" to="/incidents" :title="$t('incidents.plural')">
                <v-list-item-icon>
                    <v-icon>report</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('incidents.plural')}}</v-list-item-title>
            </v-list-item>

            <!--Users-->
            <v-list-item v-if="canViewUser" @click="" to="/users" :title="$t('users.plural')">
                <v-list-item-icon>
                    <v-icon>people</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('users.plural')}}</v-list-item-title>
            </v-list-item>

            <!--Settings-->
            <v-list-group v-if="canManageSettings || canManageTourGuides" prepend-icon="settings" no-action
                          :title="$t('general.settings')">
                <template v-slot:activator>
                    <v-list-item-title>{{$t('general.settings')}}</v-list-item-title>
                </template>
                <v-list-item v-if="canManageSettings" @click="" to="/medical-offices">
                    <v-list-item-title>{{$t('medical_offices.plural')}}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canManageSettings" @click="" to="/insurance-companies">
                    <v-list-item-title>{{$t('insurance_companies.plural')}}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canManageSettings || canManageTourGuides" @click="" to="/tour-guides">
                    <v-list-item-title>{{$t('tour_guides.plural')}}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canManageSettings" @click="" to="/bank-accounts">
                    <v-list-item-title>{{$t('bank_accounts.plural')}}</v-list-item-title>
                </v-list-item>
            </v-list-group>

        </v-list>

    </v-navigation-drawer>
</template>

<script type="text/babel">
    export default {
        props: ['drawer', 'mini_menu'],
        data: self => ({
            sidebar_drawer: self.drawer,
            sidebar_mini_menu: self.mini_menu,

            // menu permissions
            canViewIncident: false,
            canCreateIncident: false,
            canViewUser: false,
            canManageSettings: false,
            canManageTourGuides: false
        }),
        created() {
            this.$store.watch(
                (state, getters) => state.user_details, async (new_value, old_value) => {

                    // fetching orders finished
                    if (new_value) {
                        this.canViewIncident = await this.$store.dispatch('hasPermission', 'view_incident');
                        this.canCreateIncident = await this.$store.dispatch('hasPermission', 'create_incident');
                        this.canViewUser = await this.$store.dispatch('hasPermission', 'view_user');
                        this.canManageSettings = await this.$store.dispatch('hasPermission', 'manage_settings');
                        this.canManageTourGuides = await this.$store.dispatch('hasPermission', 'view_tour_guide');
                    }
                }
            );
        },
        async mounted() {
            this.canViewIncident = await this.$store.dispatch('hasPermission', 'view_incident');
            this.canCreateIncident = await this.$store.dispatch('hasPermission', 'create_incident');
            this.canViewUser = await this.$store.dispatch('hasPermission', 'view_user');
            this.canManageSettings = await this.$store.dispatch('hasPermission', 'manage_settings');
            this.canManageTourGuides = await this.$store.dispatch('hasPermission', 'view_tour_guide');
        },
        watch: {
            // change on prop drawer
            drawer(val) {
                this.sidebar_drawer = val;
            },
            // change on prop mini menu
            mini_menu(val) {
                this.sidebar_mini_menu = val;
            },
            // change on drawer in sidebar
            sidebar_drawer(val) {
                this.$emit('drawerChanged', val);
            },
            // change on mini menu in sidebar
            sidebar_mini_menu(val) {
                this.$emit('miniMenuChanged', val);
            }
        }
    }
</script>