import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter);

const routes = [
    /**
     * Guest routes
     */
    {
        path: '/login',
        name: 'login',
        component: () => import('./../views/auth/Login.vue'),
        meta: {
            guest: true
        }
    },
    {
        path: '/forgot-password/',
        name: 'forgot-password',
        component: () => import('./../views/auth/ForgotPassword.vue'),
        props: true,
        meta: {
            guest: true
        }
    },
    {
        path: '/reset-password/:reset_token',
        name: 'reset-password',
        component: () => import('./../views/auth/ResetPassword.vue'),
        props: true,
        meta: {
            guest: true
        }
    },
    /**
     * Auth routes
     */
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
            auth: true
        }
    },
    // incidents
    {
        path: '/incidents',
        name: 'incidents',
        component: () => import('../views/incidents/Incidents.vue'),
        meta: {
            auth: true,
            permission: 'view_incident'
        }
    },
    // users
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/users/Users.vue'),
        meta: {
            auth: true,
            permission: 'view_user'
        }
    },
    // medical offices
    {
        path: '/medical-offices',
        name: 'medical_offices',
        component: () => import('../views/settings/MedicalOffices.vue'),
        meta: {
            auth: true,
            permission: 'manage_settings'
        }
    },
    // insurance companies
    {
        path: '/insurance-companies',
        name: 'insurance_companies',
        component: () => import('../views/settings/InsuranceCompanies.vue'),
        meta: {
            auth: true,
            permission: 'manage_settings'
        }
    },
    // tour guides
    {
        path: '/tour-guides',
        name: 'tour_guides',
        component: () => import('../views/settings/TourGuides.vue'),
        meta: {
            auth: true,
            permission: 'view_tour_guide'
        }
    },
    // bank accounts
    {
        path: '/bank-accounts',
        name: 'bank_accounts',
        component: () => import('../views/settings/BankAccounts.vue'),
        meta: {
            auth: true,
            permission: 'manage_settings'
        }
    },
    // Not found
    {
        path: '/page-not-found',
        name: 'page-not-found',
        component: () => import('../views/404.vue'),
        meta: {
            auth: true
        }
    },
    // Not matching route
    {
        path: '*',
        component: () => import('../views/404.vue'),
        meta: {
            auth: true
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {

    const guest = to.matched.some(record => record.meta.guest);
    const auth = to.matched.some(record => record.meta.auth);

    // Do not allow user to visit login page page if they are logged in
    if (guest && store.getters.isLoggedIn) {
        return next('/');
    }

    // Do not allow user to visit pages that require authentication
    if (auth && !store.getters.isLoggedIn) {
        return next({
            path: '/login'
        });
    }

    // If access to routes requires permission
    if (to.meta.permission !== undefined) {
        if (!(await store.dispatch('hasPermission', to.meta.permission))) {
            return next('/page-not-found');
        }
    }

    next();
});

export default router
