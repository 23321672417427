<template>
    <v-app>

        <!--sidebar-->
        <Sidebar v-if="isAuthenticated" :drawer="drawer" :mini_menu="mini_menu"
                 @drawerChanged="drawerChanged" @miniMenuChanged="miniMenuChanged"></Sidebar>

        <!--header-->
        <Header :isAuthenticated="isAuthenticated" :drawer="drawer" :mini_menu="mini_menu"
                @drawerChanged="drawerChanged" @miniMenuChanged="miniMenuChanged"></Header>

        <!--content-->
        <v-content>
            <ResponseNotification></ResponseNotification>
            <router-view></router-view>
        </v-content>

        <!--footer-->
        <Footer></Footer>

    </v-app>
</template>

<script>
    import Header from './components/Header.vue'
    import Sidebar from './components/Sidebar.vue'
    import Footer from './components/Footer.vue'
    import ResponseNotification from './components/ResponseNotification.vue'

    export default {
        name: 'App',
        components: {
            Header, Sidebar, Footer, ResponseNotification
        },
        data: () => ({
            drawer: true,
            mini_menu: false
        }),
        computed: {
            isAuthenticated() {
                return this.$store.getters.isLoggedIn && this.$store.getters.showContent;
            }
        },
        created() {
            window.addEventListener('beforeunload', this.leaving)
        },
        watch: {
            '$route'(to, from) {
                if (['incidents'].indexOf(to.name) > -1) {
                    setTimeout(() => {
                        this.mini_menu = true;
                    }, 50);
                }
            }
        },
        methods: {
            // when leaving browser tab or closing tab
            leaving() {

                // if in updating incident page
                if (['update_incident'].indexOf(this.$route.name) > -1) {

                    // unlock incident
                    this.$store.dispatch('apiRequest', {
                        method: 'patch', url: `incidents/locked/${this.$route.params.incident_id}`,
                        data: {locked_by_user: 0}, hide_response: true
                    });
                }
            },
            // change on drawer
            drawerChanged(val) {
                this.drawer = val;
            },
            // change on menu mini
            miniMenuChanged(val) {
                this.mini_menu = val;
            }
        }
    };
</script>
