export default {
    methods: {
        // return string that has been matched in the dictionary
        toDictionary(val) {
            let str = '';
            let dictionary = [
                {from: 'α', to: 'a'}, {from: 'β', to: 'b'}, {from: 'γ', to: 'g'}, {from: 'δ', to: 'd'},
                {from: 'ε', to: 'e'}, {from: 'ζ', to: 'z'}, {from: 'η', to: 'h'}, {from: 'θ', to: 'u'},
                {from: 'ι', to: 'i'}, {from: 'κ', to: 'k'}, {from: 'λ', to: 'l'}, {from: 'μ', to: 'm'},
                {from: 'ν', to: 'n'}, {from: 'ξ', to: 'j'}, {from: 'ο', to: 'o'}, {from: 'π', to: 'p'},
                {from: 'ρ', to: 'r'}, {from: 'σ', to: 's'}, {from: 'τ', to: 't'}, {from: 'υ', to: 'y'},
                {from: 'φ', to: 'f'}, {from: 'χ', to: 'x'}, {from: 'ψ', to: 'c'}, {from: 'ω', to: 'v'}
            ];

            for (let i = 0, len = val.length; i < len; i++) {

                //check if current char in dictionary
                let found = dictionary.find(obj => obj.from === val[i] || obj.to === val[i]);

                if (found) {
                    str += found.from === val[i] ? found.to : found.from;
                } else {
                    str += val[i];
                }
            }

            return str;
        },
        // sort array of objects
        sortArrayOfObjects(data) {

            let dateFormat = /(^\d{1,4}[\.|\\/|-]\d{1,2}[\.|\\/|-]\d{1,4})(\s*(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d\s*[ap]m)?$/;

            return data.array.sort((a, b) => {

                // given property is a date
                if (dateFormat.test(a[data.property]) && dateFormat.test(b[data.property])) {

                    let parts_a = a[data.property].split('/');
                    let parts_b = b[data.property].split('/');

                    let name_a = new Date(parts_a[2], parts_a[1] - 1, parts_a[0]);
                    let name_b = new Date(parts_b[2], parts_b[1] - 1, parts_b[0]);

                    return data.descending ? name_b - name_a : name_a - name_b;

                    // not a date
                } else {

                    let name_a = a[data.property] ? a[data.property].toUpperCase() : a[data.property];
                    let name_b = b[data.property] ? b[data.property].toUpperCase() : b[data.property];

                    return data.descending ? (name_a < name_b ? 1 : name_a > name_b ? -1 : 0)
                        : (name_a < name_b ? -1 : name_a > name_b ? 1 : 0);
                }
            });
        },
        // format given date
        formatDate(date) {
            if (!date) {
                return null;
            }

            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        },
        // format file size to appropriate size type
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed([1, 2].indexOf(i) > -1 ? 0 : dm))} ${sizes[i]}`;
        },
        // createSearchFilters
        createSearchFilters(search_values) {
            let filters = {};

            // get all filters that are not null
            for (let prop in search_values) {
                if (search_values[prop] !== null) {
                    if (search_values[prop].constructor === Array) {
                        if (search_values[prop].length > 0) {
                            filters[prop] = search_values[prop];
                        }
                    } else {
                        filters[prop] = search_values[prop];
                    }
                }
            }

            return filters;
        },
        // concert number to words
        convertNumberToWords(amount) {
            let words = [
                '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve',
                'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen', 'Twenty'
            ];
            words[30] = 'Thirty';
            words[40] = 'Forty';
            words[50] = 'Fifty';
            words[60] = 'Sixty';
            words[70] = 'Seventy';
            words[80] = 'Eighty';
            words[90] = 'Ninety';

            amount = amount.toString();
            let atemp = amount.split(".");
            let number = atemp[0].split(",").join("");
            let n_length = number.length;
            let words_string = "";

            if (n_length <= 9) {
                let n_array =[0, 0, 0, 0, 0, 0, 0, 0, 0];
                let received_n_array = [];
                for (let i = 0; i < n_length; i++) {
                    received_n_array[i] = number.substr(i, 1);
                }
                for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
                    n_array[i] = received_n_array[j];
                }
                for (let i = 0, j = 1; i < 9; i++, j++) {
                    if (i == 0 || i == 2 || i == 4 || i == 7) {
                        if (n_array[i] == 1) {
                            n_array[j] = 10 + parseInt(n_array[j]);
                            n_array[i] = 0;
                        }
                    }
                }
                let value = "";
                for (let i = 0; i < 9; i++) {
                    if (i == 0 || i == 2 || i == 4 || i == 7) {
                        value = n_array[i] * 10;
                    } else {
                        value = n_array[i];
                    }
                    if (value != 0) {
                        words_string += words[value] + " ";
                    }
                    if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Crores ";
                    }
                    if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Lakhs ";
                    }
                    if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Thousand ";
                    }
                    if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                        words_string += "Hundred and ";
                    } else if (i == 6 && value != 0) {
                        words_string += "Hundred ";
                    }
                }
                words_string = words_string.split("  ").join(" ");
            }
            return words_string;
        },
        // convert decimal number to words
        numberToWordsWithDecimal(n) {
            let nums = n.toString().split('.');
            let whole = this.convertNumberToWords(nums[0]);

            if (nums.length == 2) {
                let fraction = this.convertNumberToWords(nums[1]);
                return whole + 'and ' + fraction;
            } else {
                return whole;
            }
        }
    }
}