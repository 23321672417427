<template>
    <v-app-bar color="white" app :clipped-left="$vuetify.breakpoint.mdAndUp" fixed>

        <v-app-bar-nav-icon v-if="isAuthenticated" class="menu_btn" @click.stop=menuIconClicked>
        </v-app-bar-nav-icon>

        <v-toolbar-title>
            <router-link to="/">
                <img :src="require('../assets/images/aegean_logo.png')" alt="Aegean"/>
            </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>

            <!--<v-menu v-if="show_languages" bottom left :offset-y="true">-->
                <!--<template v-slot:activator="{ on }">-->
                    <!--<v-btn text v-on="on">{{$i18n.locale}}</v-btn>-->
                <!--</template>-->
                <!--<v-list>-->
                    <!--<v-list-item v-for="(lang, index) in languages" :key="index" @click="changeLanguage(lang.locale)">-->
                        <!--<v-list-item-title>{{ lang.name }}</v-list-item-title>-->
                    <!--</v-list-item>-->
                <!--</v-list>-->
            <!--</v-menu>-->

            <v-btn icon class="logout_button" v-if="isAuthenticated">
                <v-icon @click="logout" :title="$t('auth.logout')">exit_to_app</v-icon>
            </v-btn>

        </v-toolbar-items>
    </v-app-bar>
</template>

<script type="text/babel">
    import _ from 'lodash'

    export default {
        props: ['isAuthenticated', 'drawer', 'mini_menu'],
        data: self => ({
            header_drawer: self.drawer,
            header_mini_menu: self.mini_menu,
            show_languages: false,
            languages: [
                {name: 'Greek', locale: 'el'},
                {name: 'English', locale: 'en'}
            ]
        }),
        watch: {
            // change on prop drawer
            drawer(val) {
                this.header_drawer = val;
            },
            // change on prop mini menu
            mini_menu(val) {
                this.header_mini_menu = val;
            },
            // change on drawer in header
            header_drawer(val) {
                this.$emit('drawerChanged', val);
            },
            // change on mini menu in header
            header_mini_menu(val) {
                this.$emit('miniMenuChanged', val);
            }
        },
        methods: {
            // click app bar menu icon
            menuIconClicked() {
                (this.$vuetify.breakpoint.width > 1263) ? this.header_mini_menu = !this.header_mini_menu
                    : this.header_drawer = !this.header_drawer;
            },
            // change locale and set axios header
            changeLanguage(locale) {
                this.$i18n.locale = locale;
                axios.defaults.headers.common['Locale'] = locale;
            },
            // call API to logout user
            async logout() {

                let response = await this.$store.dispatch('apiRequest', {method: 'post', url: 'logout'});

                // successful response
                if (!response.response) {
                    // call store function to set variables after successful logout
                    await this.$store.dispatch('logoutProcess');
                }
            }
        }
    }
</script>